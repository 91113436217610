import Provider, { EthereumProvider } from '@walletconnect/ethereum-provider';
import { useEffect, useState } from 'react';
import { CHAINS_LIST, WALLETCONNECT_V2_RPC } from '../constant/defaultConfig';

export function useWalletConnecProvider(): Provider | undefined {
  const [provider, setProvider] = useState<Provider>();

  async function onInitializeprovider() {
    const provider = await EthereumProvider.init({
      projectId: import.meta.env.VITE_APP_WALLETCONNECT_PROJECT_ID as string,
      showQrModal: true,
      qrModalOptions: { themeMode: 'light' },
      chains: [1],
      optionalChains: [
        1,
        137,
        11155111,
        CHAINS_LIST?.[0]?.chainId,
        CHAINS_LIST?.[1]?.chainId,
        CHAINS_LIST?.[2]?.chainId,
        CHAINS_LIST?.[3]?.chainId,
      ],
      methods: [
        'personal_sign',
        'eth_sendTransaction',
        'eth_accounts',
        'eth_requestAccounts',
        'eth_call',
        'eth_getBalance',
        'eth_sendRawTransaction',
        'eth_sign',
        'eth_signTransaction',
        'eth_signTypedData',
        'eth_signTypedData_v3',
        'eth_signTypedData_v4',
        'wallet_switchEthereumChain',
        'wallet_addEthereumChain',
        'wallet_getPermissions',
        'wallet_requestPermissions',
        'wallet_registerOnboarding',
        'wallet_watchAsset',
        'wallet_scanQRCode',
      ],
      events: [
        'accountsChanged',
        'chainChanged',
        'message',
        'disconnect',
        'connect',
        'session_update',
        'session_delete',
      ],
      rpcMap: WALLETCONNECT_V2_RPC,
    });
    provider.on('session_delete', () => {
      console.log('session_delete');
      setProvider(undefined);
    });
    setProvider(provider);
  }

  useEffect(() => {
    onInitializeprovider();
  }, []);

  return provider;
}
